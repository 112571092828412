import notifications from "@/components/common/notifications";
import auditTrail from "@/components/common/auditTrail.vue";
import listAPI from "@/api/listAPI.js";
import patientAPI from "@/api/patientAPI.js";
import store from "@/store/index";
import SubHeader from "@/components/SubHeader.vue";
import Header from "@/components/Header.vue";
import validations from "@/validations/index";
import permissions from "@/api/permissions.js";

export default {
  components: {
    Header,
    SubHeader,
    notifications,
    auditTrail,
  },
  data: () => ({
    formData: {
      id: null,
      patient_id: null,
      event_date: null,
      event_type_id: null,
      remote_service_id: null,
      comment: null,
      is_completed: "F",
      scheduling_type: null,
      frequency: null,
      frequency_unit: null,
    },
    formDataEdit: {},
    notificationPopup: false,
    auditTarilPatientId: null,
    auditTrailPopup: false,

    sendNotificationLoading: true,
    loading: true,
    showDeleteAll: false,
    apsNotification: { channel: {} },
    contentValue: null,
    sendNotification: false,
    contentNotification: {},

    appointment_id: null,
    vrChannel: [],
    vrLetterType: [],
    vrRemoteServiceList: [],
    vrEventType: [],
    vrFrequencyUnit: null,
    dialog: true,
    displayEventPopup: false,
    editEvent: false,
    date: false,
    menu2: null,
    menu: null,
    appointments: null,
    //options: { page: 1, itemsPerPage: 25 },
    options: {},
    totalRecords: 0,
    tblLoading: false,
    vrAppointments: [],
    headers: [
      // { sortable: false, text: "#", value: "id" },
      {
        sortable: false,
        text: "EVENT DATE",
        value: "event_date_display",
        width: "10%",
      },
      {
        sortable: false,
        text: "EVENT TYPE",
        value: "event_type_description",
        width: "13%",
      },
      {
        sortable: false,
        text: "REMOTE FOLLOW UP",
        value: "remote_serv_name",
        width: "22%",
      },
      { sortable: false, text: "COMMENT", value: "comment", width: "30%" },
      { sortable: false, text: "", value: "action", width: "23%" },
    ],
    tab: false,
    scheduleType: "RF",
    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }),
  watch: {
    options: {
      handler() {
        this.loadPatientScheduling();
      },
      deep: true,
    },
  },
  methods: {
    deleteSchedule(vrItem) {
      //this.formData = item
      let vrDeleteAll = false;

      if (!confirm("Are you sure, you want to delete?")) {
        return false;
      }

      if (vrItem == "all") {
        vrDeleteAll = true;
        vrItem = {};
      }

      patientAPI
        .deleteSchedule({
          id: vrItem.id,
          schedule_type: this.scheduleType,
          delete_all: vrDeleteAll,
          patient_id: this.vrPatientID,
        })
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .then(() => {
          this.loadPatientScheduling();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    EditSchedule(item) {
      //this.formData = item

      patientAPI
        .getpatientGetScheduling({
          id: item.id,
          schedule_type: this.scheduleType,
          pagination: { itemsPerPage: -1 },
        })
        .then((response) => {
          if (response.data.results !== null)
            //this.vrAppointments = response.data.results.Appointments;
            this.formDataEdit = response.data.results.Appointments[0];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});

      //Hide the popup
      this.editEvent = true;
    },

    SaveData(schedulingType) {
      let vrSaveData;
      this.formData.schedule_type = this.scheduleType;
      this.formDataEdit.schedule_type = this.scheduleType;

      if (schedulingType !== "UPDATE") {
        //insert case
        this.formData.scheduling_type = schedulingType;

        vrSaveData = this.formData;
      } else {
        //Update/Edit case
        vrSaveData = this.formDataEdit;
      }

      //Selected Patient ID#
      vrSaveData.patient_id = this.vrPatientID;

      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid && this.scheduleType == "RF") {
        isValid = validations.hasValue(vrSaveData.event_type_id);
      }
      if (isValid && this.scheduleType == "RF") {
        isValid = validations.hasValue(vrSaveData.remote_service_id);
      }

      if (isValid && this.scheduleType == "CL" && schedulingType == 'A') {
        isValid = validations.hasValue(vrSaveData.event_time_automatic);
      }

      if (isValid && this.scheduleType == "CL" && (schedulingType == 'M' || schedulingType == 'UPDATE')) {
        isValid = validations.hasValue(vrSaveData.event_time_manual);
      }

      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      patientAPI
        .saveScheduling(vrSaveData)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              //this.formData = {};
              this.formDataEdit = {};
            }
          }
        })
        .then(() => {
          //Refresh scheduling table with updated data
          this.loadPatientScheduling();

          //Hide the popup
          this.editEvent = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },

    loadPatientScheduling() {
      this.tblLoading = true;

      patientAPI
        .getpatientGetScheduling({
          patient_id: this.vrPatientID,
          schedule_type: this.scheduleType,
          pagination: this.options,
        })
        .then((response) => {
          if (response.data.results !== null)
            this.vrAppointments = response.data.results.Appointments;
          this.totalRecords =
            response.data.results.AppointmentCount.total_count;
        })
        .then(() => {
          //Handle delete all
          if (this.totalRecords !== null) {
            //alert(this.totalRecords.length)
            if (this.totalRecords > 0) {
              this.showDeleteAll = true;
            } else {
              this.showDeleteAll = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tblLoading = false;
        });
    },

    showNotificationPopUp(vrItem) {
      this.sendNotificationLoading = false;
      this.notificationPopup = true;
      this.appointment_id = vrItem.id;
      this.apsNotification = { channel: {} };
      this.contentNotification = {};
      this.apsNotification.remote_service_id = vrItem.remote_service_id;
      this.apsNotification.event_type_id = vrItem.event_type_id;
    },
    handleNotification() {
      this.sendNotificationLoading = false;

      if (
        this.apsNotification.channel == null ||
        this.apsNotification.remote_service_id == null ||
        this.apsNotification.event_type_id == null ||
        this.apsNotification.letter_type == null
      ) {
        this.sendNotification = false;
        this.contentNotification = {};
        return;
      }

      //Patient Id
      this.apsNotification.patient_id = this.vrPatientID;
      //Appointment Id
      this.apsNotification.appointment_id = this.appointment_id;

      patientAPI
        .getNotificationText(this.apsNotification)
        .then((response) => {
          if (response.data.results !== null) {
            if (response.data.status == false) {
              this.vrNotify.type = response.data.error_type;
              this.vrNotify.message = response.data.message;
              this.vrNotify.visible = true;
            }
            this.contentNotification = response.data.results;
            this.sendNotification = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    updateNotificationText(evt) {
      this.contentNotification.mail_content = evt.target.innerHTML;
    },
    sendSaveNotification() {
      this.sendNotificationLoading = true;

      if (
        this.apsNotification.channel == null ||
        this.apsNotification.remote_service_id == null ||
        this.apsNotification.event_type_id == null ||
        this.apsNotification.letter_type == null
      ) {
        this.sendNotificationLoading = false;
        this.sendNotification = false;
        this.contentNotification = {};
        return;
      }

      let vrSendNotificationData = Object.assign({}, this.apsNotification);

      //Patient Id
      vrSendNotificationData.patient_id = this.vrPatientID;

      //vrSendNotificationData.is_letter_sent = "N";

      //letter_content
      vrSendNotificationData.letter_content =
        this.contentNotification.mail_content;

      //contentNotification.patientdetail
      console.log(this.contentNotification);

      //letter email
      if (vrSendNotificationData.channel.lookup_value == "E") {
        vrSendNotificationData.sent_to = this.contentNotification.e_mail;
        vrSendNotificationData.e_mail = this.contentNotification.e_mail;
      }

      //Phone1, Phone2
      if (vrSendNotificationData.channel.lookup_value == "T") {
        vrSendNotificationData.sent_to = this.contentNotification.phone_num1;
        vrSendNotificationData.phone_num1 = this.contentNotification.phone_num1;
        vrSendNotificationData.phone_num2 = this.contentNotification.phone_num2;
      }

      patientAPI
        .sendNotification(vrSendNotificationData)
        .then((response) => {
          if (response.data.results !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            this.sendNotification = true;

            if (response.data.status) {
              if (
                this.apsNotification.channel != null &&
                this.apsNotification.channel.lookup_value == "P"
              ) {
                //call print function
                this.printNotification();
              } else {
                this.notificationPopup = false;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.sendNotificationLoading = false;
        });
    },
    selectScheduleType(vrScheduleType) {
      this.scheduleType = vrScheduleType;
      //this.options.page = 1;
      this.loadPatientScheduling();

      //alert(vrScheduleType);
      //--Admin Role-- Handle permission of adding notes
      if (store.getters.user.role_id == 1 || store.getters.user.role_id == 2) {
        if (vrScheduleType == "CL") {
          this.permissions.allowAdd = false;
          this.permissions.allowEdit = false;
          this.permissions.allowDelete = false;
        } else {
          this.permissions = permissions.getPermissions("search");
        }
      }

      //--User Role-- Handle permission of adding notes
      if (store.getters.user.role_id == 3) {
        if (vrScheduleType == "RF") {
          this.permissions.allowAdd = false;
          this.permissions.allowEdit = false;
          this.permissions.allowDelete = false;
        } else {
          this.permissions.allowAdd = true;
          this.permissions.allowEdit = true;
          this.permissions.allowDelete = true;
        }
      }
    },
    printNotification() {
      this.sendNotificationLoading = false;
      // Get HTML to print from element
      const prtHtml = document.getElementById("printDiv").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "APS",
        "left=10,top=10,width=800,height=900,toolbar=0,scrollbars=0,status=0,location=no,titlebar=no"
      );

      WinPrint.document.write(
        "<!DOCTYPE html><html><head><title>APS</title>" +
          stylesHtml +
          "</head><body>" +
          prtHtml +
          "</body></html>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
      return true;
    },
    handleAuditTrailPopup() {
      this.auditTarilPatientId = this.vrPatientID;
      this.auditTrailPopup = true;
    },
  },
  beforeMount() {
    listAPI
      .getEventTypeList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrEventType = response.data.results.Event_Type;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getFrequencyUnitList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrFrequencyUnit = response.data.results.Lookup;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getRemoteServiceList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrRemoteServiceList = response.data.results.Remote_Service;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    //CHANNEL_TYPE
    listAPI
      .getLookup("CHANNEL_TYPE")
      .then((response) => {
        this.vrChannel = response.data.results.Lookup;
      })
      .catch((error) => {
        console.log(error);
      });

    listAPI
      .getLookup("LETTER_TYPE")
      .then((response) => {
        this.vrLetterType = response.data.results.Lookup;
      })
      .catch((error) => {
        console.log(error);
      });

    //this.options.page = 1;
    //this.loadPatientScheduling();
    this.selectScheduleType("RF");
  },
  computed: {
    vrPatientID() {
      return store.state.patientId;
    },
    vrNotify() {
      return store.state.notify;
    },
    vrRedirectURL() {
      return store.state.redirectURL;
    },
    computedHeaders() {
      return this.headers.filter(
        (header) =>
          //this.scheduleType == "RF" ||
          this.scheduleType == "RF" ||
          (this.scheduleType == "CL" &&
            header.value != "event_type_description" &&
            header.value != "remote_serv_name")
      );
    },
  },
  mounted() {},
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
