import notifications from "@/components/common/notifications";
import listAPI from "@/api/listAPI.js";
import reportsAPI from "@/api/reportsAPI.js";
import store from "@/store/index";
import router from "@/router/index.js";
import Header from "@/components/Header.vue";
//import axios from "axios";
import permissions from "@/api/permissions.js";

export default {
  name: "report-appointment",
  components: {
    Header,
    notifications,
  },
  data: () => ({
    options: { page: 1 },
    totalRecords: 0,
    tblLoading: false,
    vrAppointments: [],
    vrStatus: [],
    vrEventType: null,
    vrRemoteServiceList: null,
    clearable: true,

    //Diagnosis-----------
    vrDiagnosis: [],
    //model_diagnosis: null,
    isLoadingDiagnosis: false,
    searchDiagnosis: null,

    headers: [
      // { sortable: false, text: "#", value: "id" },
      {
        sortable: false,
        text: "PATIENT ID",
        value: "patient_code",
        width: "6%",
      },
      {
        sortable: false,
        text: "APPOINTMENT",
        value: "event_date",
        width: "7%",
      },
      {
        sortable: false,
        text: "PATIENT NAME",
        value: "full_name",
        width: "10%",
      },
      {
        sortable: false,
        text: "EVENT TYPE",
        value: "event_type",
        width: "10%",
      },
      {
        sortable: false,
        text: "REMOTE SERVICES",
        value: "remote_services",
        width: "12%",
      },
      {
        sortable: false,
        text: "AFFILIATION",
        value: "affiliate_id",
        width: "8%",
      },
      {
        sortable: false,
        text: "COMPLETED",
        value: "is_completed",
        width: "5%",
      },
      {
        sortable: false,
        text: "STATUS",
        value: "patient_status",
        width: "10%",
      },
      {
        sortable: false,
        text: "PHONE",
        value: "phone",
        width: "10%",
      },
      {
        sortable: false,
        text: "EMAIL",
        value: "email",
        width: "12%",
      },
      {
        sortable: false,
        text: "DIAGNOSIS",
        value: "diagnosis",
        width: "12%",
      },
    ],
    formData: {
      start_date: null,
      end_date: null,
      affiliate_id: null,
      status_id: null,
      event_type_id: null,
      diagnosis_id: null,
      remote_services_id: null,
      show_scheduled: "T",
      show_missed: "T",
      show_completed: null,
      phone: null,
      email: null,
      schedule_type: "RF",
    },

    exportLoadingLabel: false,
    exportLoadingExcel: false,
    exportLoadingCSV: false,
    exportLoadingPDF: false,

    disableExport: false,

    isPostback: false,

    reportsURL: {
      label: "exportDocuments/appointmentSearchReportExportToLabel",
      csv: "exportDocuments/appointmentSearchReportExportToCsv",
      excel: "exportDocuments/appointmentSearchReportExportToExcel",
      pdf: "exportDocuments/appointmentSearchReportExportToPdf",
    },
    scheduleType: "RF",

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }), //End Data

  watch: {
    options: {
      handler() {
        this.loadAppointmentReport();
      },
      deep: true,
    },
    searchDiagnosis(val) {
      // Items have already been requested
      if (this.isLoadingDiagnosis) return; //Return

      this.isLoadingDiagnosis = true; //initiate loading

      if (val !== null) {
        listAPI
          .getDiagnosisList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this.vrDiagnosis = response.data.results.Diagnosis;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDiagnosis = false;
          });
      }

      this.isLoadingDiagnosis = false;
    },
  },
  methods: {
    selectPatient(item) {
      //Set the patient Id for other screens/flow
      store.state.patientId = item.patient_id;

      store.state.redirectURL = "report-appointment";

      router.push("patient");
    },
    loadAppointmentReport: function (e) {
      if (!this.isPostback) {
        this.vrAppointments = [];
        this.totalRecords = 0;
        this.disableExport = true;

        this.isPostback = true;
        return false;
      }

      this.tblLoading = true;
      this.formData.pagination = this.options;
      this.formData.exportType = null;
      this.formData.schedule_type = this.scheduleType;

      reportsAPI
        .getAppointmentSearchReport(this.formData)
        .then((response) => {
          if (response.data.results !== null) {
            this.vrAppointments = response.data.results.Patients;
            this.totalRecords = response.data.results.PatientCount.total_count;
            if (this.totalRecords > 0) {
              this.disableExport = false;
            } else {
              this.disableExport = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.disableExport = true;
        })
        .finally(() => {
          this.tblLoading = false;
        });

      if (!!e == true) e.preventDefault();
    },

    exportLabel() {
      this.formData.exportType = "LABEL";
      this.exportLoadingLabel = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToLabel",
          this.reportsURL.label,
          this.formData,
          "Patient-Details-Label.xlsx",
          this.exportLoadingLabel
        )
        .then(() => {
          this.exportLoadingLabel = false;
        });
    },
    exportCSV() {
      this.formData.exportType = "CSV";
      this.exportLoadingCSV = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToCsv",
          this.reportsURL.csv,
          this.formData,
          "Patient-Details.csv"
        )
        .then(() => {
          this.exportLoadingCSV = false;
        });
    },
    exportExcel() {
      this.formData.exportType = "EXCEL";
      this.exportLoadingExcel = true;
      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToExcel",
          this.reportsURL.excel,
          this.formData,
          "Patient-Details.xlsx"
        )
        .then(() => {
          this.exportLoadingExcel = false;
        });
    },
    exportPDF() {
      this.formData.exportType = "PDF";
      this.exportLoadingPDF = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToPdf",
          this.reportsURL.pdf,
          this.formData,
          "Patient-Details.pdf"
        )
        .then(() => {
          this.exportLoadingPDF = false;
        });
    },

    clearAllFields() {
      this.formData = {};
      this.formData.show_scheduled = "T";
      this.formData.show_missed = "T";
      this.formData.schedule_type = "RF";
      this.scheduleType = "RF";
      this.options.page = 1;
      this.isPostback = false;
      this.loadAppointmentReport();
    },
    schedulingTypeChangeEvent() {
      //alert(this.scheduleType)
    },
  },

  beforeMount() {
    listAPI
      .getEventTypeList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrEventType = response.data.results.Event_Type;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getFrequencyUnitList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrFrequencyUnit = response.data.results.Lookup;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getRemoteServiceList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrRemoteServiceList = response.data.results.Remote_Service;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getPatientSearchMasters()
      .then((response) => {
        this.vrState = response.data.results.States;
        this.vrStatus = response.data.results.Status;
        this.vrRemoteService = response.data.results.RemoteServices;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    vrPatientID() {
      return store.state.patientId;
    },
    vrNotify() {
      return store.state.notify;
    },
    computedHeaders() {
      //alert(this.formData.schedule_type);
      return this.headers.filter(
        (header) =>
          //this.scheduleType == "RF" ||
          this.formData.schedule_type == "RF" ||
          (this.formData.schedule_type == "CL" &&
            header.value != "event_type" &&
            header.value != "remote_services")
      );
    },
  },
  mounted() {},
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
